import _throttle from 'lodash.throttle'

const navBtn = document.querySelector('.c-mobile-header__toggle');
const menu = document.querySelector('.c-header__menu');
const nav = document.querySelector('.c-header__nav');
const header = document.querySelector('.c-header');

const navToggle = () => {

  nav.classList.toggle('is-active');
  menu.classList.toggle('is-active');
  navBtn.classList.toggle('is-active');
  header.classList.toggle('is-active');

}

if(navBtn) {
  navBtn.addEventListener('click',navToggle);
}
