import { CountUp } from 'countup.js'
import inView from 'in-view';

const itemsToCount = document.querySelectorAll(
    '.u-countup',
);
const siteLang = document.documentElement.attributes['lang'].value;
const isGer = siteLang === 'de-DE'

if (itemsToCount) {
    itemsToCount.forEach(value => {
        const valueNum = Number(value.innerText);

        const countValue = new CountUp(value, valueNum, {
            duration: 5,
            separator: '.',
            decimalPlaces: 0,
        });

        inView('.u-countup').on('enter', element => {
            countValue.start()
        });
    });
}