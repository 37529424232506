import Macy from 'macy';

window.addEventListener('DOMContentLoaded', () => {

    const macyGrid = Macy({
        container: '.c-info-grid',
        trueOrder: true,
        mobileFirst: true,
        margin: {
            x: 20,
            y: 20
        },
        columns: 1,
        breakAt: {
            767: {
                columns: 2,
                margin: {
                    x: 20,
                    y: 20
                },
            } ,
            1024: {
                columns: 3,
                margin: {
                    x: 30,
                    y: 30
                },
            }
        }
    });

    // Get all "trigger" elements
    const $trigger = Array.prototype.slice.call(
        document.querySelectorAll('.u-trigger'),
        0,
    );

    // Check if there are any navbar burgers
    if ($trigger.length > 0) {
        // Add a click event on each of them
        $trigger.forEach(el => {
            el.addEventListener('click', e => {

                macyGrid.recalculate(true);

                setTimeout(() => {
                    macyGrid.recalculate(true);
                },5)

                const macyRefresh = setInterval(() => {
                    macyGrid.recalculate(true);
                },20);

                setTimeout(() => {
                    clearInterval(macyRefresh);
                },1000)

                e.preventDefault();
                // Get the target from the "data-target" attribute
                const { target, triggerClassName: className } = el.dataset;
                const { triggerClose, triggerCloseId } = el.dataset;
                const targetArr = target.split(',');

                if(triggerClose) {
                    const $closeElms = document.querySelectorAll('[data-id="'+triggerClose+'"]');
                    $closeElms.forEach(closeElm =>  {
                        if(el.dataset.target !== closeElm.id) {
                            closeElm.classList.remove(className || 'is-active');

                            if (closeElm.hasAttribute('aria-expanded')) {
                                closeElm.setAttribute('aria-expanded', 'false');
                            }
                        }
                    });
                }

                if(triggerCloseId) {
                    const $closeElms = document.querySelectorAll('[data-trigger-close-id="'+triggerCloseId+'"]');
                    $closeElms.forEach(closeElm =>  {
                        if(el.dataset.target !== closeElm.dataset.target) {
                            closeElm.classList.remove(className || 'is-active');

                            if (closeElm.hasAttribute('aria-expanded')) {
                                closeElm.setAttribute('aria-expanded', 'false');
                            }
                        }
                    });
                }

                targetArr.forEach(t => {
                    const $target = document.getElementById(t);
                    $target.classList.toggle(className || 'is-active');
                });

                el.classList.toggle(className || 'is-active');

                // Update aria data if exist
                if (el.hasAttribute('aria-expanded')) {
                    const attr = el.getAttribute('aria-expanded');
                    const isTrue = attr === 'true';
                    el.setAttribute('aria-expanded', !isTrue ? 'true' : 'false');
                }


            });
        });
    }
});